export const ONE_YEAR = 31536000;
export const getDefYear = (league = "") => {
  if (league === "") {
    return "2024";
  } else if (league === "nba" || league === "NBA") {
    return "2025";
  } else if (league === "ncaam" || league === "college_mens" || league === "NCAA" || league === "ncaa") {
    return "2025";
  } else if (league === "ncaaw" || league === "college_womens" || league === "NCAAW") {
    return "2024";
  } else if (league === "wnba" || league === "WNBA") {
    return "2024";
  }
  return "2024";
};
export const getYearArray = (league = "") => {
  if (league === "") {
    return ["2022", "2023", "2024"];
  } else if (league === "nba" || league === "NBA") {
    return ["2020", "2021", "2022", "2023", "2024", "2025"];
  } else if (league === "ncaam" || league === "college_mens" || league === "NCAA" || league === "ncaa") {
    return ["2020", "2021", "2022", "2023", "2024", "2025"];
  } else if (league === "ncaaw" || league === "college_womens" || league === "NCAAW") {
    return ["2021", "2022", "2023", "2024"];
  } else if (league === "wnba" || league === "WNBA") {
    return ["2020", "2021", "2022", "2023", "2024"];
  } else if (league === "japan_b1" || league === "JAPAN_B1") {
    return ["2020", "2021", "2022", "2023", "2024"];
  } else if (league === "el_salvador") {
    return ["2022", "2023", "2024"];
  }
  return ["2022", "2023", "2024"];
};

export const miscTableHeader = [
  {
    title: "Game Date & Time",
    showImage: false,
    sortable: false,
    key: "Date",
    classList: ["mm-stretch-column"],
  },
  {
    title: "NBA Basketball Matchup",
    showImage: false,
    sortable: false,
    key: "Matchup",
    classList: ["mm-stretch-column"],
  },

  {
    title: "Predicted Matchup Score",
    showImage: false,
    sortable: false,
    key: "SQProjection",
    classList: ["mm-stretch-column"],
  },

  {
    title: "Expert Prediction",
    showImage: false,
    sortable: false,
    key: "Plays",
    classList: ["mm-stretch-column"],
  },
];

export const teamStatsHeader = [
  {
    title: "Team",
    showImage: false,
    sortable: true,
    key: "Team_Name",
  },
  {
    title: "Conference",
    showImage: false,
    sortable: true,
    key: "Conference",
  },
  {
    title: "adj SQ",
    showImage: false,
    sortable: true,
    key: "Adjusted_Shot_Quality",
    tooltip: {
      title: "Adjusted Shot Quality",
      text: "Adjusted ShotQuality difference: adjOFF SQ minus adjDEF SQ.",
      link: "/stats-explained#adj-sq",
    },
  },
  {
    title: "adjOFF SQ",
    showImage: false,
    sortable: true,
    key: "Offensive_Shot_Quality",
    rankKey: "Offensive_Shot_Quality_Rank_Visual",
    tooltip: {
      title: "Adjusted Offensive ShotQuality",
      text: "Offensive SQ points divided by possessions, but adjusting for strength of schedule for each team",
      link: "/stats-explained#adj-sq",
    },
  },
  {
    title: "adjDEF SQ",
    showImage: false,
    sortable: true,
    key: "Defensive_Shot_Quality",
    rankKey: "Defensive_Shot_Quality_Rank_Visual",
    tooltip: {
      title: "Adjusted Defensive ShotQuality",
      text: "Defensive SQ points divided by possessions, but adjusting for strength of schedule for each team.",
      link: "/stats-explained#adj-sq",
    },
  },
  {
    title: "Rim & 3 Rate",
    showImage: false,
    sortable: true,
    key: "rim_and_3_rate",
    tooltip: {
      title: "Rim & 3 Rate",
      text: "Percent of possession that resulted in a three pointer or a shot at the rim (the two most valued shots!)",
      link: "/stats-explained#Rim&3",
    },
  },
  {
    title: "Record",
    showImage: false,
    sortable: true,
    key: "Actual_Record",
  },
  {
    title: "SQ Record",
    showImage: false,
    sortable: true,
    key: "SQ_Record",
    classList: ["min-width-100px"],
    tooltip: {
      title: "Shot Quality Record",
      text: "Projected record based on shots taken by both teams. Total accumulation of “win game %” stat.",
      link: "/stats-explained#Rim&3",
    },
  },
  {
    title: "Win%",
    showImage: false,
    sortable: true,
    key: "Actual_Win_Percentage",
  },
  {
    title: "SQ Win%",
    showImage: false,
    sortable: true,
    key: "SQ_Win_Percentage",
    tooltip: {
      title: "Shot Quality Win Percentage",
      text: "Projected win percentage based on Shot Quality",
    },
  },
  {
    title: "Record Luck",
    showImage: false,
    sortable: true,
    key: "Record_Luck",
    tooltip: {
      title: "Record Luck",
      text: "(SQ Win Percentage - Actual Win Percentage) *100. If positive, team is due for positive regression. If negative, team is due for negative regression!",
      link: "/stats-explained#record-luck",
    },
  },
];
//team page
export const playerStatsHeader2020 = [
  {
    title: "Players",
    showImage: false,
    sortable: true,
    key: "Players",
  },
  {
    title: "Possessions",
    showImage: false,
    sortable: true,
    key: "Possessions",
  },
  {
    title: "SQ PPP",
    showImage: false,
    sortable: true,
    key: "SQ PPP",
    tooltip: {
      title: "Shot Quality Points per Possesssion",
      text: "Total SQ points scored divided by total possessions",
      link: "/stats-explained#sq-points",
    },
  },
  {
    title: "SQ PPP Rank",
    showImage: true,
    sortable: false,
    key: "SQ PPP Rank",
  },
  {
    title: "GPR",
    showImage: false,
    sortable: true,
    key: "Good_Possession_Rate",
    tooltip: {
      title: "Good Possession Rate",
      text: "The percent of possessions that are in the 75th percentile or above (based on SQpoints). The 75th percentile is 1.20 SQpoints.",
      link: "/stats-explained#good-possessions",
    },
  },
  {
    title: "BPR",
    showImage: false,
    sortable: true,
    key: "Bad_Possession_Rate",
    tooltip: {
      title: "Bad Possession Rate",
      text: "The percent of possessions that are in the 25th percentile or below (based on SQpoints). The 25th percentile is 0.85 SQpoints.",
      link: "/stats-explained#bad-possessions",
    },
  },
  {
    title: "Assists per game",
    showImage: false,
    sortable: true,
    key: "Assists_per_game",
  },
  {
    title: "SQ Assists",
    showImage: false,
    sortable: true,
    key: "SQ_Assists_per_game",
    tooltip: {
      title: "Shot Quality Assists",
      text: "Analyzed every pass and calculated the probability that each shot would go in, and would therefore be an assist.",
      link: "/stats-explained#passing-stats",
    },
  },
  {
    title: "SQ Passing Points Created per Game",
    showImage: false,
    sortable: true,
    key: "SQ_Passing_Points_Created_per_game",
  },
];

export const playerStatsHeader2021 = [
  {
    title: "Players",
    showImage: false,
    sortable: true,
    key: "Players",
  },
  {
    title: "Possessions",
    showImage: false,
    sortable: true,
    key: "Possessions",
  },
  {
    title: "SQ PPP",
    showImage: false,
    sortable: true,
    key: "SQ PPP",
    tooltip: {
      title: "Shot Quality Points per Possesssion",
      text: "Total SQ points scored divided by total possessions",
      link: "/stats-explained#sq-points",
    },
  },
  {
    title: "SQ PPP Rank",
    showImage: true,
    sortable: false,
    key: "SQ PPP Rank",
  },
  {
    title: "GPR",
    showImage: false,
    sortable: true,
    key: "Good_Possession_Rate",
    tooltip: {
      title: "Good Possession Rate",
      text: "The percent of possessions that are in the 75th percentile or above (based on SQpoints). The 75th percentile is 1.20 SQpoints.",
      link: "/stats-explained#good-possessions",
    },
  },
  {
    title: "BPR",
    showImage: false,
    sortable: true,
    key: "Bad_Possession_Rate",
    tooltip: {
      title: "Bad Possession Rate",
      text: "The percent of possessions that are in the 25th percentile or below (based on SQpoints). The 25th percentile is 0.85 SQpoints.",
      link: "/stats-explained#bad-possessions",
    },
  },
  {
    title: "Rim & 3 Rate",
    showImage: false,
    sortable: true,
    key: "Rim_and_3_rate",
  },
  {
    title: "Assists per game",
    showImage: false,
    sortable: true,
    key: "Assists_per_game",
  },
  {
    title: "SQ Assists",
    showImage: false,
    sortable: true,
    key: "SQ_Assists_per_game",
    tooltip: {
      title: "Shot Quality Assists",
      text: "Analyzed every pass and calculated the probability that each shot would go in, and would therefore be an assist.",
      link: "/stats-explained#passing-stats",
    },
  },
  {
    title: "SQ Passing Points Created per Game",
    showImage: false,
    sortable: true,
    key: "SQ_Passing_Points_Created_per_game",
  },
];

export const teamSummaryHeader2020 = [
  {
    title: "adj SQ",
    showImage: false,
    sortable: false,
    key: "Adjusted_Shot_Quality",
    tooltip: {
      title: "Adjusted Shot Quality",
      text: "Adjusted ShotQuality difference: adjOFF SQ minus adjDEF SQ.",
      link: "/stats-explained#adj-sq",
    },
  },
  {
    title: "adjOFF SQ",
    showImage: false,
    sortable: false,
    key: "Offensive_Shot_Quality",
    rankKey: "Offensive_Shot_Quality_Rank_Visual",
    tooltip: {
      title: "Adjusted Offensive Shot Quality",
      text: "Offensive SQ points divided by possessions, but adjusting for strength of schedule for each team",
      link: "/stats-explained#adj-sq",
    },
  },
  {
    title: "adjDEF SQ",
    showImage: false,
    sortable: false,
    key: "Defensive_Shot_Quality",
    rankKey: "Defensive_Shot_Quality_Rank_Visual",
    tooltip: {
      title: "Adjusted Defensive Shot Quality",
      text: "Defensive SQ points divided by possessions, but adjusting for strength of schedule for each team.",
      link: "/stats-explained#adj-sq",
    },
  },
  {
    title: "Record",
    showImage: false,
    sortable: false,
    key: "Actual_Record",
  },
  {
    title: "SQ Record",
    showImage: false,
    sortable: false,
    key: "SQ_Record",
    tooltip: {
      title: "Shot Quality Record",
      text: "Projected record based on Shot Quality.",
    },
  },
  {
    title: "Win%",
    showImage: false,
    sortable: false,
    key: "Actual_Win_Percentage",
  },
  {
    title: "SQ Win%",
    showImage: false,
    sortable: false,
    key: "SQ_Win_Percentage",
    tooltip: {
      title: "Shot Quality Win Percentage",
      text: "Projected win percentage based on Shot Quality",
    },
  },
  {
    title: "Record Luck",
    showImage: false,
    sortable: false,
    key: "Record_Luck",
    tooltip: {
      title: "Record Luck",
      text: "(SQ Win Percentage - Actual Win Percentage) *100. If positive, team is due for positive regression. If negative, team is due for negative regression!",
      link: "/stats-explained#record-luck",
    },
  },
];

export const teamSummaryHeader2021 = [
  {
    title: "adj SQ",
    showImage: false,
    sortable: false,
    key: "Adjusted_Shot_Quality",
    tooltip: {
      title: "Adjusted Shot Quality",
      text: "Adjusted ShotQuality difference: adjOFF SQ minus adjDEF SQ.",
      link: "/stats-explained#adj-sq",
    },
  },
  {
    title: "adjOFF SQ",
    showImage: false,
    sortable: false,
    key: "Offensive_Shot_Quality",
    rankKey: "Offensive_Shot_Quality_Rank_Visual",
    tooltip: {
      title: "Adjusted Offensive Shot Quality",
      text: "Offensive SQ points divided by possessions, but adjusting for strength of schedule for each team",
      link: "/stats-explained#adj-sq",
    },
  },
  {
    title: "adjDEF SQ",
    showImage: false,
    sortable: false,
    key: "Defensive_Shot_Quality",
    rankKey: "Defensive_Shot_Quality_Rank_Visual",
    tooltip: {
      title: "Adjusted Defensive Shot Quality",
      text: "Defensive SQ points divided by possessions, but adjusting for strength of schedule for each team.",
      link: "/stats-explained#adj-sq",
    },
  },
  {
    title: "Rim and 3 Rate",
    showImage: false,
    sortable: false,
    key: "rim_and_3_rate",
    tooltip: {
      title: "Rim & 3 Rate",
      text: "Percent of possession that resulted in a three pointer or a shot at the rim (the two most valued shots!)",
      link: "/stats-explained#Rim&3",
    },
  },
  {
    title: "Record",
    showImage: false,
    sortable: false,
    key: "Actual_Record",
  },
  {
    title: "SQ Record",
    showImage: false,
    sortable: false,
    key: "SQ_Record",
    tooltip: {
      title: "Shot Quality Record",
      text: "Projected record based on Shot Quality.",
    },
  },
  {
    title: "Win%",
    showImage: false,
    sortable: false,
    key: "Actual_Win_Percentage",
  },
  {
    title: "SQ Win%",
    showImage: false,
    sortable: false,
    key: "SQ_Win_Percentage",
    tooltip: {
      title: "Shot Quality Win Percentage",
      text: "Projected win percentage based on Shot Quality",
    },
  },
  {
    title: "Record Luck",
    showImage: false,
    sortable: false,
    key: "Record_Luck",
    tooltip: {
      title: "Record Luck",
      text: "(SQ Win Percentage - Actual Win Percentage) *100. If positive, team is due for positive regression. If negative, team is due for negative regression!",
      link: "/stats-explained#record-luck",
    },
  },
];

export const allPlaysHeaderList = [
  {
    title: "Play ran",
    showImage: false,
    sortable: true,
    key: "Play_Ran",
  },
  {
    title: "Occurrences",
    showImage: false,
    sortable: true,
    key: "Occurrences",
  },
  {
    title: "SQ PPP",
    showImage: false,
    sortable: true,
    key: "SQ_PPP",
  },
  {
    title: "%ile Rk",
    showImage: false,
    sortable: false,
    key: "Percentile_rank",
  },
];

export const shotTypeAnalysisCategories = {
  playstyles: ["Half Court", "Transition"],
  playtypes: ["P&R Ball Screen", "Off-Screen", "Cut", "Isolation"],
  shottypes: ["Finishing at the Rim", "Catch & Shoot 3PT", "Off the Dribble 3PT", "Midrange", "Post-Up"],
};

export const scheduleRecordHeader = [
  {
    title: "Date",
    showImage: false,
    sortable: true,
    key: "Date",
  },
  {
    title: "Opponent",
    showImage: false,
    sortable: true,
    key: "Opponent",
  },
  {
    title: "Result",
    showImage: false,
    sortable: true,
    key: "Result",
    classList: ["min-width-100px"],
  },
  {
    title: "SQ Result",
    showImage: false,
    sortable: true,
    key: "SQ_Result",
    classList: ["min-width-100px"],
  },
  {
    title: "Win Game%",
    showImage: false,
    sortable: true,
    key: "Win_Game_Percent",
    classList: ["max-width-5"],
  },
  {
    title: "Spread",
    showImage: false,
    sortable: true,
    key: "current_spread",
  },
  {
    title: "Total",
    showImage: false,
    sortable: true,
    key: "current_total",
  },
  {
    title: "Location",
    showImage: false,
    sortable: true,
    key: "Location",
  },
];

export const sqDocSliderSettings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: false,
        infinite: true,
        autoPlay: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: false,
        dots: false,
        centerMode: false,
        infinite: true,
        autoPlay: true,
      },
    },
  ],
};
